var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav',{staticStyle:{"justify-content":"space-between"}},[_c('button',{staticClass:"button button-fill",class:{ disabled: _vm.tabDisabled(_vm.activeTab - 1) },on:{"click":function($event){return _vm.$refs['main-tabs'].prevTab()}}},[_c('fa-icon',{attrs:{"icon":['fas', 'chevron-left']}}),_c('span',[_vm._v(_vm._s(_vm.$t("back")))])],1),(_vm.activeTab < 3)?_c('button',{staticClass:"button button-fill",class:{ disabled: _vm.tabDisabled(_vm.activeTab + 1) },on:{"click":function($event){return _vm.$refs['main-tabs'].nextTab()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("next")))]),_c('fa-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1):_vm._e(),(_vm.activeTab == 3)?_c('button',{staticClass:"button button-fill",class:{ disabled: !_vm.lineasSelected().length },on:{"click":_vm.finalizar}},[_c('span',[_vm._v("Finalizar")])]):_vm._e()]),_c('section',[_c('article',{staticStyle:{"height":"536px","width":"975px"}},[_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"0px","left":"0px","height":"535px"},attrs:{"wt":_vm.formData.wt,"buttons":[
          '1. Proveedor',
          '2. Divisa y metal',
          '3. Pendiente de facturar',
          '4. Líneas pendientes' ],"classButton":function (i) { return ({ disabled: _vm.tabDisabled(i) }); },"styleButton":'width:33%;'},on:{"active":_vm.onActiveTab}},[_c('div',{staticClass:"tab"},[_c('fieldset',{staticStyle:{"position":"relative","left":"80px","top":"40px","width":"430px","height":"200px"},attrs:{"model":"factura_proveedor"}},[_c('legend',[_vm._v("Seleccione proveedor")]),_c('field',{staticStyle:{"top":"47px","left":"70px"},attrs:{"name":"proveedor_id","widget":"m2o","labelProp":"nombre_comercial","width":"320px","fields":[
                {
                  name: 'forma_pago_id',
                  fields: [
                    'codigo',
                    'descripcion',
                    'vencimientos',
                    'periodicidad',
                    'periodicidad_unidades' ],
                } ]}}),_c('field',{staticStyle:{"top":"70px","left":"70px"},attrs:{"name":"pedidos","widget":"checkbox","label":"Pedidos","reverse":"","inline-label":""}}),_c('field',{staticStyle:{"top":"85px","left":"70px"},attrs:{"name":"albaranes","widget":"checkbox","label":"Albaranes","reverse":"","inline-label":""}}),_c('field',{staticStyle:{"top":"100px","left":"70px"},attrs:{"name":"condicionales","widget":"checkbox","label":"Condicionales","reverse":"","inline-label":""}}),(_vm.showWt)?_c('field',{staticStyle:{"top":"115px","left":"70px"},attrs:{"name":"notas_pedidos","widget":"checkbox","label":"Prop. de Pedidos","reverse":"","inline-label":""}}):_vm._e(),(_vm.showWt)?_c('field',{staticStyle:{"top":"130px","left":"70px"},attrs:{"name":"notas_albaranes","widget":"checkbox","label":"Prop. de Albaranes","reverse":"","inline-label":""}}):_vm._e(),(_vm.showWt)?_c('field',{staticStyle:{"top":"145px","left":"70px"},attrs:{"name":"notas_condicionales","widget":"checkbox","label":"Prop. de Condicionales","reverse":"","inline-label":""}}):_vm._e()],1)]),_c('div',{staticClass:"tab"},[_c('field',{ref:"pre_documentos_pendientes",staticStyle:{"top":"5px","left":"4px","width":"953px"},attrs:{"name":"pre_documentos_pendientes","widget":"handsontable","height":413,"width":950,"minRows":18,"columns":[
              {
                name: 'sel',
                header: 'Sel.',
                widget: 'checkbox',
                help: 'Seleccionado',
              },
              {
                name: 'proveedor_id',
                header: 'Proveedor',
                readOnly: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre_comercial',
                showCode: true,
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                readOnly: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              {
                name: 'metal_id',
                header: 'Metal',
                readOnly: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'metal',
                showCode: true,
              },
              {
                name: 'PEDIDO',
                header: 'Pedidos',
                type: 'numeric',
                readOnly: true,
              },
              {
                name: 'CONDICIONAL',
                header: 'Condicionales',
                type: 'numeric',
                readOnly: true,
              },
              {
                name: 'ALBARÁN',
                header: 'Albaranes',
                type: 'numeric',
                readOnly: true,
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"5px","left":"8px"},attrs:{"name":"divisa_id","widget":"m2o","label":"Divisa","width":"120px"}}),_c('field',{staticStyle:{"top":"5px","left":"135px"},attrs:{"name":"cambio","widget":"float","dec":"2","label":"Cambio","width":"100px"}}),_c('fieldset',{staticStyle:{"position":"relative","left":"240px","top":"5px","width":"375px","height":"60px"}},[_c('legend',[_vm._v("Metal")]),_c('field',{ref:"tipo_metal",staticStyle:{"top":"5px","left":"15px"},attrs:{"name":"tipo_metal","widget":"radio","default":"no","options":{ No: 'no', Cuenta: 'cuenta', Factura: 'factura' }}}),_c('field',{ref:"metal_id",staticStyle:{"top":"5px","left":"192px"},attrs:{"name":"metal_id","widget":"m2o","labelProp":"descripcion","readonly":_vm.formData.tipo_metal != 'cuenta' &&
                _vm.formData.tipo_metal != 'factura',"width":"170px","fields":['cotizacion', 'factor']}})],1),_c('div',{staticStyle:{"position":"relative","left":"620px","top":"-50px","width":"333px"}},[_c('field',{staticStyle:{"top":"0px","left":"0px"},attrs:{"name":"prmetalp","widget":"float","type":"number","dec":"2","inputStyle":"text-align:center;","label":"Pr. Metal/P","width":"70px","readonly":_vm.formData.tipo_metal != 'cuenta' &&
                _vm.formData.tipo_metal != 'factura'}}),_c('field',{staticStyle:{"top":"35px","left":"0px","font-size":"9px"},attrs:{"name":"factor","widget":"float","inputStyle":"text-align:right;font-size:10px;","type":"number","dec":"4","inline-label":"","label":"Factor","width":"40px","readonly":""}}),_c('field',{staticStyle:{"top":"0px","left":"83px"},attrs:{"name":"merma","widget":"float","type":"number","dec":"2","inputStyle":"text-align:center;","label":"Merma (%/m)","width":"70px","readonly":_vm.formData.tipo_metal != 'cuenta' &&
                _vm.formData.tipo_metal != 'factura'}}),_c('field',{staticStyle:{"top":"0px","left":"166px"},attrs:{"name":"contraste","widget":"float","type":"number","dec":"2","inputStyle":"text-align:center;","label":"Contraste/P","width":"70px"}}),_c('field',{staticStyle:{"top":"0px","left":"249px"},attrs:{"name":"ocostes","widget":"float","type":"number","dec":"2","inputStyle":"text-align:center;","label":"O. Costes/P","width":"70px"}}),_c('field',{staticStyle:{"top":"38px","left":"212px"},attrs:{"name":"mermaporlinea","widget":"checkbox","inline-label":"","label":"Merma por Línea"}})],1),_c('field',{ref:"documentos_pendientes",staticStyle:{"top":"75px","left":"4px","width":"953px"},attrs:{"name":"documentos_pendientes","widget":"handsontable","height":413,"width":950,"minRows":18,"customprop":"docs_customprop","allowDeleteRows":false,"columns":[
              {
                name: 'sel',
                header: 'Sel.',
                widget: 'checkbox',
                help: 'Seleccionado',
              },
              { name: 'tipo_doc', header: 'T', readOnly: true },
              { name: 'ndoc', header: 'NºDocumento', readOnly: true },
              {
                name: 'proveedor_id',
                header: 'Proveedor',
                readOnly: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre_comercial',
                showCode: true,
              },
              {
                name: 'fecha',
                type: 'date2',
                header: 'Fecha',
                readOnly: true,
              },
              {
                name: 'total',
                header: 'Total',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                readOnly: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              {
                name: 'cambio',
                header: 'Cambio',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              } ]}}),_c('span',{staticStyle:{"position":"absolute","top":"50px","left":"5px"}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Seleccionar todos'),expression:"'Seleccionar todos'"}],attrs:{"name":"sel_all_docs"},on:{"click":_vm.sellAllDocs}},[_c('fa-icon',{attrs:{"icon":['far', 'check-square']}})],1),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Desseleccionar todos'),expression:"'Desseleccionar todos'"}],attrs:{"name":"dessel_all_docs"},on:{"click":_vm.desselAllDocs}},[_c('fa-icon',{attrs:{"icon":['far', 'square']}})],1)])],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"10px","left":"10px"},attrs:{"name":"total_lineas","widget":"float","readonly":"","dec":"2","label":"Total","width":"100px"}}),_c('field',{staticStyle:{"top":"4px","right":"10px"},attrs:{"name":"total_unidades","widget":"float","readonly":"","dec":"2","label":"Unidades","width":"100px"}}),_c('field',{staticStyle:{"top":"36px","right":"10px"},attrs:{"name":"total_peso","widget":"float","readonly":"","dec":"2","label":"Peso","width":"100px"}}),_c('field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formData.facturar_n_cada_articulo),expression:"!formData.facturar_n_cada_articulo"}],staticStyle:{"top":"50px","left":"60px"},attrs:{"name":"dividir_factura","widget":"checkbox","inline-label":"","label":"Dividir factura"},on:{"change":function($event){_vm.$set(_vm.formData, 'importe_a', _vm.formData.total_lineas);
              _vm.$set(_vm.formData, 'importe_b', 0);}}}),_c('field',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.formData.dividir_factura && !_vm.formData.facturar_n_cada_articulo
            ),expression:"\n              formData.dividir_factura && !formData.facturar_n_cada_articulo\n            "}],staticStyle:{"top":"10px","left":"185px"},attrs:{"name":"importe_a","widget":"float","type":"number","dec":"2","label":"Importe A","width":"100px"},on:{"change":function($event){parseFloat(_vm.formData.importe_a) >
              parseFloat(_vm.formData.total_lineas)
                ? _vm.$set(_vm.formData, 'importe_a', _vm.formData.total_lineas)
                : null;
              _vm.$set(
                _vm.formData,
                'importe_b',
                _vm.formData.total_lineas - _vm.formData.importe_a
              );}}}),_c('field',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.formData.dividir_factura && !_vm.formData.facturar_n_cada_articulo
            ),expression:"\n              formData.dividir_factura && !formData.facturar_n_cada_articulo\n            "}],staticStyle:{"top":"10px","left":"295px"},attrs:{"name":"importe_b","widget":"float","type":"number","dec":"2","label":"Importe B","width":"100px"},on:{"change":function($event){parseFloat(_vm.formData.importe_b) >
              parseFloat(_vm.formData.total_lineas)
                ? _vm.$set(_vm.formData, 'importe_b', _vm.formData.total_lineas)
                : null;
              _vm.$set(
                _vm.formData,
                'importe_a',
                _vm.formData.total_lineas - _vm.formData.importe_b
              );}}}),_c('field',{staticStyle:{"top":"10px","left":"450px"},attrs:{"name":"facturar_n_cada_articulo","widget":"checkbox","inline-label":"","label":"Facturar N de cada articulo"}}),(_vm.formData.facturar_n_cada_articulo)?_c('field',{staticStyle:{"top":"40px","left":"450px"},attrs:{"name":"unidades_maximas","type":"number","widget":"int","label":"Uds. máx. a facturar por artículo (N)","inline-label":"","width":"100px"}}):_vm._e(),_c('field',{ref:"lineas_pendientes",staticStyle:{"top":"75px","left":"4px","width":"953px"},attrs:{"name":"lineas_pendientes","widget":"handsontable","label":"Líneas","height":413,"width":950,"minRows":18,"allowDeleteRows":false,"customprop":"lines_customprop","columns":[
              {
                name: 'sel',
                header: 'Sel.',
                widget: 'checkbox',
                help: 'Seleccionado',
              },
              { name: 'ean13', header: '||||||||', readOnly: true },
              {
                name: 'articulo_id',
                header: 'Artículo',
                readOnly: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
                noOpen: true,
                fields: [
                  { name: 'thumbnail', fields: ['imagen'] },
                  'tarifa1',
                  'tarifa2',
                  'tarifa3',
                  'tarifa4',
                  'tarifa5',
                  'tarifa6',
                  'tarifa7',
                  'tarifa8',
                  'tarifa9',
                  'tarifa10' ],
              },
              { name: 'descripcion', header: 'Descripción', readOnly: true },
              {
                name: 'modelo_id',
                header: 'Modelo',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
                readOnly: true,
              },
              {
                name: 'refproveedor',
                header: 'R. Proveedor',
                readOnly: true,
              },
              { name: 'nota', header: 'Nota', readOnly: true },
              {
                name: 'almacen_id',
                header: 'Almacén',
                readOnly: true,
                type: 'm2o',
                primary: 'codigo',
                readOnly: true,
              },
              {
                name: 'unidades_pendientes',
                header: 'Ud. Ptes.',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                help: 'Unidades pendientes de albaranar',
              },
              {
                name: 'peso_pendiente',
                header: 'Peso Pte.',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
                help: 'Peso pendiente de albaranar',
              },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'c',
                header: 'C',
                readOnly: true,
                type: 'select',
                options: ['U', 'P'],
              },
              { name: 'm', header: 'M', readOnly: true, type: 'checkbox' },
              { name: 'r', header: 'R', readOnly: true, type: 'checkbox' },

              {
                name: 'precio',
                header: 'Precio',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'totallinea',
                header: 'Total Línea',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              { name: 'm2', header: 'M', readOnly: true, type: 'checkbox' },
              { name: 'c2', header: 'C', readOnly: true, type: 'checkbox' },
              { name: 'o', header: 'O', readOnly: true, type: 'checkbox' },
              {
                name: 'precio_coste',
                header: 'P. Coste',
                readOnly: true,
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'coste_fijo',
                header: 'Coste Fijo',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'taqueria_ids',
                header: 'Taquería',
                readOnly: true,
                type: 'm2m',
                primary: 'codigo',
                image: 'thumbnail.imagen',
                label: 'codigo',
                fields: ['precio_coste'],
                filter: function () { return ['seccion_id', '=', 'PCK']; },
              },
              {
                name: 'taqueria_precio',
                header: 'Taq.(€)',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'incluir_taqueria',
                header: 'P',
                readOnly: true,
                type: 'checkbox',
              },
              {
                name: 'otros_costes',
                header: 'O. Costes',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'v',
                header: 'V',
                readOnly: true,
                type: 'select',
                options: ['U', 'P'],
              },

              { name: 'm3', header: 'M', readOnly: true, type: 'checkbox' },
              {
                name: 'margen',
                header: '%',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'pventa',
                header: 'PVP',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 't',
                header: 'T',
                readOnly: true,
                type: 'select',
                options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
              } ]}}),_c('span',{staticStyle:{"position":"absolute","top":"50px","left":"5px"}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Seleccionar todos'),expression:"'Seleccionar todos'"}],attrs:{"name":"sel_all_lines"},on:{"click":_vm.sellAllLines}},[_c('fa-icon',{attrs:{"icon":['far', 'check-square']}})],1),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Desseleccionar todos'),expression:"'Desseleccionar todos'"}],attrs:{"name":"dessel_all_lines"},on:{"click":_vm.desselAllLines}},[_c('fa-icon',{attrs:{"icon":['far', 'square']}})],1)])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }