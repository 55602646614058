<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between">
      <button
        class="button button-fill"
        :class="{ disabled: tabDisabled(activeTab - 1) }"
        @click="$refs['main-tabs'].prevTab()"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{ $t("back") }}</span>
      </button>
      <button
        class="button button-fill"
        :class="{ disabled: tabDisabled(activeTab + 1) }"
        @click="$refs['main-tabs'].nextTab()"
        v-if="activeTab < 3"
      >
        <span>{{ $t("next") }}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
      <button
        v-if="activeTab == 3"
        :class="{ disabled: !lineasSelected().length }"
        class="button button-fill"
        @click="finalizar"
      >
        <span>Finalizar</span>
      </button>
    </nav>
    <section>
      <article style="height: 536px; width: 975px">
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top: 0px; left: 0px; height: 535px"
          :buttons="[
            '1. Proveedor',
            '2. Divisa y metal',
            '3. Pendiente de facturar',
            '4. Líneas pendientes',
          ]"
          :classButton="(i) => ({ disabled: tabDisabled(i) })"
          :styleButton="'width:33%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <fieldset
              style="
                position: relative;
                left: 80px;
                top: 40px;
                width: 430px;
                height: 200px;
              "
              model="factura_proveedor"
            >
              <legend>Seleccione proveedor</legend>
              <field
                name="proveedor_id"
                widget="m2o"
                labelProp="nombre_comercial"
                width="320px"
                style="top: 47px; left: 70px"
                :fields="[
                  {
                    name: 'forma_pago_id',
                    fields: [
                      'codigo',
                      'descripcion',
                      'vencimientos',
                      'periodicidad',
                      'periodicidad_unidades',
                    ],
                  },
                ]"
              />
              <field
                name="pedidos"
                widget="checkbox"
                label="Pedidos"
                reverse
                inline-label
                style="top: 70px; left: 70px"
              />
              <field
                name="albaranes"
                widget="checkbox"
                label="Albaranes"
                reverse
                inline-label
                style="top: 85px; left: 70px"
              />
              <field
                name="condicionales"
                widget="checkbox"
                label="Condicionales"
                reverse
                inline-label
                style="top: 100px; left: 70px"
              />
              <field
                name="notas_pedidos"
                widget="checkbox"
                label="Prop. de Pedidos"
                reverse
                inline-label
                style="top: 115px; left: 70px"
                v-if="showWt"
              />
              <field
                name="notas_albaranes"
                widget="checkbox"
                label="Prop. de Albaranes"
                reverse
                inline-label
                style="top: 130px; left: 70px"
                v-if="showWt"
              />
              <field
                name="notas_condicionales"
                widget="checkbox"
                label="Prop. de Condicionales"
                reverse
                inline-label
                style="top: 145px; left: 70px"
                v-if="showWt"
              />
            </fieldset>
          </div>
          <div class="tab">
            <field
              ref="pre_documentos_pendientes"
              name="pre_documentos_pendientes"
              widget="handsontable"
              :height="413"
              :width="950"
              :minRows="18"
              style="top: 5px; left: 4px; width: 953px"
              :columns="[
                {
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado',
                },
                {
                  name: 'proveedor_id',
                  header: 'Proveedor',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre_comercial',
                  showCode: true,
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                {
                  name: 'metal_id',
                  header: 'Metal',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'metal',
                  showCode: true,
                },
                {
                  name: 'PEDIDO',
                  header: 'Pedidos',
                  type: 'numeric',
                  readOnly: true,
                },
                {
                  name: 'CONDICIONAL',
                  header: 'Condicionales',
                  type: 'numeric',
                  readOnly: true,
                },
                {
                  name: 'ALBARÁN',
                  header: 'Albaranes',
                  type: 'numeric',
                  readOnly: true,
                },
              ]"
            />
          </div>
          <div class="tab">
            <field
              name="divisa_id"
              widget="m2o"
              label="Divisa"
              width="120px"
              style="top: 5px; left: 8px"
            />
            <field
              name="cambio"
              widget="float"
              dec="2"
              label="Cambio"
              width="100px"
              style="top: 5px; left: 135px"
            />
            <fieldset
              style="
                position: relative;
                left: 240px;
                top: 5px;
                width: 375px;
                height: 60px;
              "
            >
              <legend>Metal</legend>
              <field
                ref="tipo_metal"
                name="tipo_metal"
                widget="radio"
                default="no"
                :options="{ No: 'no', Cuenta: 'cuenta', Factura: 'factura' }"
                style="top: 5px; left: 15px"
              />
              <field
                ref="metal_id"
                name="metal_id"
                widget="m2o"
                labelProp="descripcion"
                :readonly="
                  formData.tipo_metal != 'cuenta' &&
                  formData.tipo_metal != 'factura'
                "
                width="170px"
                style="top: 5px; left: 192px"
                :fields="['cotizacion', 'factor']"
              />
            </fieldset>
            <div
              style="position: relative; left: 620px; top: -50px; width: 333px"
            >
              <field
                name="prmetalp"
                widget="float"
                type="number"
                dec="2"
                inputStyle="text-align:center;"
                label="Pr. Metal/P"
                width="70px"
                :readonly="
                  formData.tipo_metal != 'cuenta' &&
                  formData.tipo_metal != 'factura'
                "
                style="top: 0px; left: 0px"
              />
              <field
                name="factor"
                widget="float"
                inputStyle="text-align:right;font-size:10px;"
                type="number"
                dec="4"
                inline-label
                label="Factor"
                width="40px"
                style="top: 35px; left: 0px; font-size: 9px"
                readonly
              />
              <field
                name="merma"
                widget="float"
                type="number"
                dec="2"
                inputStyle="text-align:center;"
                label="Merma (%/m)"
                width="70px"
                :readonly="
                  formData.tipo_metal != 'cuenta' &&
                  formData.tipo_metal != 'factura'
                "
                style="top: 0px; left: 83px"
              />
              <field
                name="contraste"
                widget="float"
                type="number"
                dec="2"
                inputStyle="text-align:center;"
                label="Contraste/P"
                width="70px"
                style="top: 0px; left: 166px"
              />
              <field
                name="ocostes"
                widget="float"
                type="number"
                dec="2"
                inputStyle="text-align:center;"
                label="O. Costes/P"
                width="70px"
                style="top: 0px; left: 249px"
              />
              <field
                name="mermaporlinea"
                widget="checkbox"
                inline-label
                label="Merma por Línea"
                style="top: 38px; left: 212px"
              />
            </div>
            <field
              ref="documentos_pendientes"
              name="documentos_pendientes"
              widget="handsontable"
              :height="413"
              :width="950"
              :minRows="18"
              style="top: 75px; left: 4px; width: 953px"
              customprop="docs_customprop"
              :allowDeleteRows="false"
              :columns="[
                {
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado',
                },
                { name: 'tipo_doc', header: 'T', readOnly: true },
                { name: 'ndoc', header: 'NºDocumento', readOnly: true },
                {
                  name: 'proveedor_id',
                  header: 'Proveedor',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre_comercial',
                  showCode: true,
                },
                {
                  name: 'fecha',
                  type: 'date2',
                  header: 'Fecha',
                  readOnly: true,
                },
                {
                  name: 'total',
                  header: 'Total',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                {
                  name: 'cambio',
                  header: 'Cambio',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]"
            />
            <span style="position: absolute; top: 50px; left: 5px">
              <button
                name="sel_all_docs"
                v-tooltip="'Seleccionar todos'"
                @click="sellAllDocs"
              >
                <fa-icon :icon="['far', 'check-square']" />
              </button>
              <button
                name="dessel_all_docs"
                v-tooltip="'Desseleccionar todos'"
                @click="desselAllDocs"
              >
                <fa-icon :icon="['far', 'square']" />
              </button>
            </span>
          </div>
          <div class="tab">
            <field
              name="total_lineas"
              widget="float"
              readonly
              dec="2"
              label="Total"
              width="100px"
              style="top: 10px; left: 10px"
            />
            <field
              name="total_unidades"
              widget="float"
              readonly
              dec="2"
              label="Unidades"
              width="100px"
              style="top: 4px; right: 10px"
            />
            <field
              name="total_peso"
              widget="float"
              readonly
              dec="2"
              label="Peso"
              width="100px"
              style="top: 36px; right: 10px"
            />
            <field
              name="dividir_factura"
              widget="checkbox"
              inline-label
              label="Dividir factura"
              style="top: 50px; left: 60px"
              v-show="!formData.facturar_n_cada_articulo"
              @change="
                $set(formData, 'importe_a', formData.total_lineas);
                $set(formData, 'importe_b', 0);
              "
            />
            <field
              name="importe_a"
              widget="float"
              type="number"
              dec="2"
              label="Importe A"
              width="100px"
              style="top: 10px; left: 185px"
              v-show="
                formData.dividir_factura && !formData.facturar_n_cada_articulo
              "
              @change="
                parseFloat(formData.importe_a) >
                parseFloat(formData.total_lineas)
                  ? $set(formData, 'importe_a', formData.total_lineas)
                  : null;
                $set(
                  formData,
                  'importe_b',
                  formData.total_lineas - formData.importe_a
                );
              "
            />
            <field
              name="importe_b"
              widget="float"
              type="number"
              dec="2"
              label="Importe B"
              width="100px"
              style="top: 10px; left: 295px"
              v-show="
                formData.dividir_factura && !formData.facturar_n_cada_articulo
              "
              @change="
                parseFloat(formData.importe_b) >
                parseFloat(formData.total_lineas)
                  ? $set(formData, 'importe_b', formData.total_lineas)
                  : null;
                $set(
                  formData,
                  'importe_a',
                  formData.total_lineas - formData.importe_b
                );
              "
            />
            <field
              name="facturar_n_cada_articulo"
              widget="checkbox"
              inline-label
              label="Facturar N de cada articulo"
              style="top: 10px; left: 450px"
            />
            <field
              name="unidades_maximas"
              type="number"
              widget="int"
              label="Uds. máx. a facturar por artículo (N)"
              inline-label
              width="100px"
              style="top: 40px; left: 450px"
              v-if="formData.facturar_n_cada_articulo"
            />

            <field
              ref="lineas_pendientes"
              name="lineas_pendientes"
              widget="handsontable"
              label="Líneas"
              :height="413"
              :width="950"
              :minRows="18"
              :allowDeleteRows="false"
              style="top: 75px; left: 4px; width: 953px"
              customprop="lines_customprop"
              :columns="[
                {
                  name: 'sel',
                  header: 'Sel.',
                  widget: 'checkbox',
                  help: 'Seleccionado',
                },
                { name: 'ean13', header: '||||||||', readOnly: true },
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  noOpen: true,
                  fields: [
                    { name: 'thumbnail', fields: ['imagen'] },
                    'tarifa1',
                    'tarifa2',
                    'tarifa3',
                    'tarifa4',
                    'tarifa5',
                    'tarifa6',
                    'tarifa7',
                    'tarifa8',
                    'tarifa9',
                    'tarifa10',
                  ],
                },
                { name: 'descripcion', header: 'Descripción', readOnly: true },
                {
                  name: 'modelo_id',
                  header: 'Modelo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'refproveedor',
                  header: 'R. Proveedor',
                  readOnly: true,
                },
                { name: 'nota', header: 'Nota', readOnly: true },
                {
                  name: 'almacen_id',
                  header: 'Almacén',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'unidades_pendientes',
                  header: 'Ud. Ptes.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  help: 'Unidades pendientes de albaranar',
                },
                {
                  name: 'peso_pendiente',
                  header: 'Peso Pte.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                  help: 'Peso pendiente de albaranar',
                },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'c',
                  header: 'C',
                  readOnly: true,
                  type: 'select',
                  options: ['U', 'P'],
                },
                { name: 'm', header: 'M', readOnly: true, type: 'checkbox' },
                { name: 'r', header: 'R', readOnly: true, type: 'checkbox' },

                {
                  name: 'precio',
                  header: 'Precio',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'totallinea',
                  header: 'Total Línea',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                { name: 'm2', header: 'M', readOnly: true, type: 'checkbox' },
                { name: 'c2', header: 'C', readOnly: true, type: 'checkbox' },
                { name: 'o', header: 'O', readOnly: true, type: 'checkbox' },
                {
                  name: 'precio_coste',
                  header: 'P. Coste',
                  readOnly: true,
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'coste_fijo',
                  header: 'Coste Fijo',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'taqueria_ids',
                  header: 'Taquería',
                  readOnly: true,
                  type: 'm2m',
                  primary: 'codigo',
                  image: 'thumbnail.imagen',
                  label: 'codigo',
                  fields: ['precio_coste'],
                  filter: () => ['seccion_id', '=', 'PCK'],
                },
                {
                  name: 'taqueria_precio',
                  header: 'Taq.(€)',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'incluir_taqueria',
                  header: 'P',
                  readOnly: true,
                  type: 'checkbox',
                },
                {
                  name: 'otros_costes',
                  header: 'O. Costes',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'v',
                  header: 'V',
                  readOnly: true,
                  type: 'select',
                  options: ['U', 'P'],
                },

                { name: 'm3', header: 'M', readOnly: true, type: 'checkbox' },
                {
                  name: 'margen',
                  header: '%',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'pventa',
                  header: 'PVP',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 't',
                  header: 'T',
                  readOnly: true,
                  type: 'select',
                  options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                },
              ]"
            />
            <span style="position: absolute; top: 50px; left: 5px">
              <button
                name="sel_all_lines"
                v-tooltip="'Seleccionar todos'"
                @click="sellAllLines"
              >
                <fa-icon :icon="['far', 'check-square']" />
              </button>
              <button
                name="dessel_all_lines"
                v-tooltip="'Desseleccionar todos'"
                @click="desselAllLines"
              >
                <fa-icon :icon="['far', 'square']" />
              </button>
            </span>
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
var floatOrZero = function (val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function () {
    return {
      title: "Generador de facturas de compra",
      dbAdapter: "factura_proveedor",
      primary: "ndoc",
      mode: "new",
      showWt: false,
      original_documentos_pendientes: [],
      original_lineas_pendientes: [],
      activeTab: 0
    };
  },
  methods: {
    finalizar() {
      var self = this;
      var initial_data = {
        cambio: self.formData.cambio,
        divisa_id: self.formData.divisa_id,
        proveedor_id: self.formData.proveedor_id,
        tipo_metal: self.formData.tipo_metal,
        metal_id: self.formData.metal_id,
        prmetalp: self.formData.prmetalp,
        factor: self.formData.factor,
        merma: self.formData.merma,
        contraste: self.formData.contraste,
        ocostes: self.formData.ocostes,
        mermaporlinea: self.formData.mermaporlinea,
        ivap: self.formData.ivap,
        rep: self.formData.rep,
        importacion: self.formData.importacion,
        formapago_id: self.formData.proveedor_id.forma_pago_id,
        dto_especial_porcentaje: self.formData.dto_especial_porcentaje,
        financiacion_porcentaje: self.formData.financiacion_porcentaje,
        tipo_impuesto: self.formData.tipo_impuesto,
      };
      var lineas_sel = [];
      self.formData.lineas_pendientes.forEach(function (o, i) {
        if (parseInt(o.sel)) {
          let oo = self.original_lineas_pendientes[i];
          oo.unidades = o.unidades;
          oo.etiqueta = o.unidades;
          oo.peso = o.peso;
          delete oo.unidades_facturadas;
          delete oo.peso_facturado;
          delete oo.unidades_devueltas;
          delete oo.peso_devuelto;
          delete oo.peso_pendiente;
          delete oo.unidades_pendientes;
          lineas_sel.push(oo);
        }
      });
      var la = [];
      var lb = [];
      if (self.formData.facturar_n_cada_articulo) {
        let unidades_maximas = self.formData.unidades_maximas;
        if (unidades_maximas < 1) {
          alert("Unidades máximas debe ser positivo");
          return;
        }
        var auxobj = {};
        lineas_sel.forEach(function (item, i) {
          if (item.articulo_id.id in auxobj) {
            if (auxobj[item.articulo_id.id].unidades >= unidades_maximas) {
              lb.push(item);
            } else {
              var quedan =
                unidades_maximas - auxobj[item.articulo_id.id].unidades;
              auxobj[item.articulo_id.id].unidades += Math.min(
                quedan,
                item.unidades
              );
              var sobran = item.unidades - quedan;
              if (sobran > 0) {
                item.unidades = sobran;
                lb.push(item);
              }
            }
          } else {
            auxobj[item.articulo_id.id] = Object.assign({}, item);
            auxobj[item.articulo_id.id].unidades = Math.min(
              item.unidades,
              unidades_maximas
            );
            sobran = item.unidades - unidades_maximas;
            if (sobran > 0) {
              item.unidades = sobran;
              lb.push(item);
            }
          }
        });
        for (var key in auxobj) {
          la.push(auxobj[key]);
        }
      } else {
        if (self.formData.dividir_factura) {
          let importe_a = self.formData.importe_a;
          var acc = 0;
          lineas_sel.forEach(function (item, i) {
            if (acc >= importe_a) {
              lb.push(item);
            } else {
              la.push(item);
            }
            acc += parseFloat(item.totallinea);
          });
        } else {
          if (self.formData.wt) lb = lineas_sel;
          else la = lineas_sel;
        }
      }
      var initial_data_b = window.$.extend(true, {}, initial_data);
      var initial_data_a = window.$.extend(true, {}, initial_data);
      if (la.length) {
        initial_data_a["lineas"] = la;
        self.app.openWindow("factura_proveedor", {
          props: {
            initialFormData: initial_data_a,
            initialMode: "new",
          },
        });
      }
      if (lb.length) {
        initial_data_b["lineas"] = lb;
        initial_data_b["wt"] = 1;
        self.app.openWindow("factura_proveedor", {
          props: {
            initialFormData: initial_data_b,
            initialMode: "new",
          },
        });
      }
      self.$emit("close");
    },
    docsSelected() {
      var self = this;
      return self.formData.documentos_pendientes
        ? self.formData.documentos_pendientes.filter((doc) => parseInt(doc.sel))
        : [];
    },
    lineasSelected() {
      var self = this;
      return self.formData.lineas_pendientes
        ? self.formData.lineas_pendientes.filter((doc) => parseInt(doc.sel))
        : [];
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          var p = Promise.resolve();
          var tts = {
            pedidos: {
              model: "pedido_proveedor",
              wt: 0,
              tipo_doc: "PEDIDO",
              condicional: undefined,
            },
            albaranes: {
              model: "albaran_proveedor",
              wt: 0,
              tipo_doc: "ALBARÁN",
              condicional: 0,
            },
            condicionales: {
              model: "albaran_proveedor",
              wt: 0,
              tipo_doc: "CONDICIONAL",
              condicional: 1,
            },
            notas_pedidos: {
              model: "pedido_proveedor",
              wt: 1,
              tipo_doc: "PEDIDO",
              condicional: undefined,
            },
            notas_albaranes: {
              model: "albaran_proveedor",
              wt: 1,
              tipo_doc: "ALBARÁN",
              condicional: 0,
            },
            notas_condicionales: {
              model: "albaran_proveedor",
              wt: 1,
              tipo_doc: "CONDICIONAL",
              condicional: 1,
            },
          };
          self.$set(self.formData, "pre_documentos_pendientes", []);
          self.$set(self.formData, "documentos_pendientes", []);
          self.original_documentos_pendientes = [];
          self.loading = true;
          for (let tt in tts) {
            if (self.formData[tt]) {
              p = p.then(
                (_) =>
                  new Promise(function (resolve, reject) {
                    let filter = [
                      ["proveedor_id", "=", self.formData.proveedor_id.codigo],
                      ["wt", "=", tts[tt].wt],
                    ];
                    if (typeof tts[tt].condicional != "undefined")
                      filter.push(["condicional", "=", tts[tt].condicional]);
                    window.DB.getList(tts[tt].model, {
                      filter,
                      fields: [
                        "ndoc",
                        "wt",
                        { name: "proveedor_id", fields: ["nombre_comercial"] },
                        "fecha",
                        "total",
                        { name: "divisa_id", fields: ["nombre"] },
                        "cambio",
                        "ivap",
                        "rep",
                        "importacion",
                        "tipo_metal",
                        {
                          name: "metal_id",
                          fields: ["descripcion", "cotizacion", "factor"],
                        },
                        "prmetalp",
                        "factor",
                        "merma",
                        "contraste",
                        "ocostes",
                        "mermaporlinea",
                        "dto_especial_porcentaje",
                        "financiacion_porcentaje",
                        "tipo_impuesto",
                        {
                          name: "lineas",
                          fields: [
                            {
                              name: "articulo_id",
                              fields: [
                                "codigo",
                                "ean13",
                                {
                                  name: "modelo_id",
                                  fields: ["codigo", "nombre"],
                                },
                                {
                                  name: "familia_id",
                                  fields: ["codigo", "nombre"],
                                },
                                {
                                  name: "tipo_comision_id",
                                  fields: ["codigo", "descripcion"],
                                },
                                "nombre",
                                "codigo_proveedor",
                                "unidad_medida_compra",
                                "compra_metal_incluido",
                                "recargo_equivalencia",
                                "incluir_contraste_pcoste",
                                "incluir_ocostes_pcoste",
                                "precio_coste",
                                "unidad_medida_venta",
                                "venta_metal_incluido",
                                "peso_unitario_fino",
                                "margen_ultima_compra",
                                {
                                  name: "taqueria_ids",
                                  fields: [
                                    "codigo",
                                    "nombre",
                                    { name: "thumbnail", fields: ["imagen"] },
                                    "precio_coste",
                                  ],
                                },
                                "taqueria_precio",
                                { name: "thumbnail", fields: ["imagen"] },
                              ],
                            },
                            "ean13",
                            { name: "modelo_id", fields: ["codigo", "nombre"] },
                            "descripcion",
                            "refproveedor",
                            "mpl",
                            "c",
                            "m",
                            "r",
                            "m2",
                            "c2",
                            "o",
                            "precio_coste",
                            "coste_fijo",
                            "precio",
                            "v",
                            "m3",
                            "t",
                            "unidades",
                            "etiqueta",
                            "peso",
                            "margen",
                            "pventa",
                            {
                              name: "taqueria_ids",
                              fields: [
                                "codigo",
                                "nombre",
                                { name: "thumbnail", fields: ["imagen"] },
                                "precio_coste",
                              ],
                            },
                            "incluir_taqueria",
                            "taqueria_precio",
                            "otros_costes",
                            {
                              name: "almacen_id",
                              fields: ["codigo", "nombre"],
                            },
                            "descuentos",
                            "totallinea",
                            { name: "origin_pedido_id", fields: ["id"] },
                            "unidades_facturadas",
                            "peso_facturado",
                            "unidades_devueltas",
                            "peso_devuelto",
                          ],
                        },
                      ],
                    })
                      .then(function (res) {
                        // TODO: filtrar en servidor
                        // Filtramos los documentos que tengan líneas con unidades pendientes (si compra=U) o con peso pendiente (si compra=P)
                        res.data = res.data.filter((doc) =>
                          doc.lineas.some(
                            (linea) =>
                              (linea.c == "U" &&
                                parseFloat(linea.unidades || 0) -
                                  parseFloat(linea.unidades_devueltas || 0) -
                                  parseFloat(linea.unidades_facturadas || 0) >
                                  0) ||
                              (linea.c == "P" &&
                                parseFloat(linea.peso || 0) -
                                  parseFloat(linea.peso_devuelto || 0) -
                                  parseFloat(linea.peso_facturado || 0) >
                                  0)
                          )
                        );
                        res.data.forEach(function (doc) {
                          doc.tipo_doc = tts[tt].tipo_doc;
                          let metal = doc.metal_id && doc.metal_id.codigo;
                          let divisa = doc.divisa_id && doc.divisa_id.codigo;
                          let md = metal + "-" + divisa;
                          doc.md = md;

                          // PRE documentos
                          let idx =
                            self.formData.pre_documentos_pendientes.findIndex(
                              (x) => x.md == md
                            );
                          if (idx == -1) {
                            self.formData.pre_documentos_pendientes.push({
                              md: md,
                              proveedor_id: doc.proveedor_id,
                              divisa_id: doc.divisa_id,
                              metal_id: doc.metal_id,
                            });
                            idx =
                              self.formData.pre_documentos_pendientes.length -
                              1;
                          }
                          let tipo_doc = tts[tt].tipo_doc;
                          if (
                            !(
                              tipo_doc in
                              self.formData.pre_documentos_pendientes[idx]
                            )
                          )
                            self.formData.pre_documentos_pendientes[idx][
                              tipo_doc
                            ] = 0;
                          self
                            .formData.pre_documentos_pendientes[idx][tipo_doc]++;

                          // Guardar todos los documentos originales
                          self.original_documentos_pendientes.push(
                            JSON.parse(JSON.stringify(doc))
                          );
                        });
                        resolve();
                      })
                      .catch(function (e) {
                        reject(e);
                      });
                  })
              );
            }
          }
          p.then(function () {
            if (!self.formData.pre_documentos_pendientes.length) {
              self.app.toast("No hay documentos pendientes", "error");
            }
            self.$forceUpdate();
          })
            .catch(function (e) {
              window.console.log("eeeeeer", e);
            })
            .finally(function () {
              self.loading = false;
            });
          break;
        case 2:
          var mds = [];
          self.formData.pre_documentos_pendientes.forEach(function (doc, i) {
            if (!parseInt(doc.sel)) return;
            mds.push(doc.md);
          });
          self.original_documentos_pendientes.forEach((x) => {
            let doc = { ...x };
            delete doc.lineas;
            self.formData.documentos_pendientes.push(doc);
          });
          break;
        case 3:
          self.$set(self.formData, "lineas_pendientes", []);
          self.original_lineas_pendientes = [];
          self.formData.documentos_pendientes.forEach(function (doc, i) {
            if (!parseInt(doc.sel)) return;
            //TODO: origin
            self.original_documentos_pendientes[i].lineas.forEach(function (
              linea
            ) {
              linea.unidades_pendientes =
                parseFloat(linea.unidades || 0) -
                parseFloat(linea.unidades_facturadas || 0) -
                parseFloat(linea.unidades_devueltas || 0);
              linea.peso_pendiente =
                parseFloat(linea.peso || 0) -
                parseFloat(linea.peso_facturado || 0) -
                parseFloat(linea.peso_devuelto || 0);
              if (
                (linea.c == "U" && !linea.unidades_pendientes) ||
                (linea.c == "P" && !linea.peso_pendiente)
              )
                return;
              linea.unidades = 0;
              linea.peso = 0;
              self.formData.lineas_pendientes.push(linea);
              let linea2 = JSON.parse(JSON.stringify(linea));
              if (doc.tipo_doc == "PEDIDO") linea2.origin_pedido_id = linea2.id;
              else linea2.origin_albaran_id = linea2.id;
              delete linea2.id;
              delete linea2.nota;
              self.original_lineas_pendientes.push(linea2);
            });
          });
          self.$forceUpdate();
      }
    },
    sellAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function (i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 1, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    desselAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function (i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 0, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    sellAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      h.getSourceData().forEach(
        (r) => (
          (r.sel = 1),
          (r.unidades = r.unidades_pendientes),
          (r.peso = r.peso_pendiente)
        )
      );
      h.render();
      self.actualizarTotales();
    },
    desselAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes?.field.hotInstance;
      if (!h) return;
      h.getSourceData().forEach(
        (r) => ((r.sel = 0), (r.unidades = 0), (r.peso = 0))
      );
      h.render();
      self.actualizarTotales();
    },
    tabDisabled(i) {
      var self = this;
      switch (i) {
        case 1:
          return (
            !self.formData.proveedor_id ||
            (!self.formData.pedidos &&
              !self.formData.albaranes &&
              !self.formData.condicionales &&
              !self.formData.notas_pedidos &&
              !self.formData.notas_albaranes &&
              !self.formData.notas_condicionales)
          );
        case 2:
          return !(self.formData.pre_documentos_pendientes || []).some((doc) =>
            parseInt(doc.sel || 0)
          );
        case 3:
          return !self.docsSelected().length;
      }
    },
    onChange(arrProps, callback) {
      this.$on("change", function (prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    },
    actualizarTotales() {
      var self = this;
      var total_lineas = 0;
      var total_unidades = 0;
      var total_peso = 0;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      h.getSourceData().forEach((r) => {
        if (parseInt(r.sel || 0)) {
          total_lineas +=
            parseFloat(r.unidades || 0) * parseFloat(r.precio || 0); //TODO: compra a peso?
          total_unidades += parseFloat(r.unidades || 0);
          total_peso += parseFloat(r.peso || 0);
        }
      });
      self.formData.total_lineas = total_lineas.toFixed(2);
      self.formData.total_unidades = total_unidades.toFixed(2);
      self.formData.total_peso = total_peso.toFixed(2);
      self.$set(self.formData, "dividir_factura", 0);
      self.$forceUpdate();
    },
  },
  mounted() {
    var self = this;
    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (self.app.session.mode == "a") return;
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          if (
            self.mode != "edit" &&
            self.mode != "new" &&
            self.mode != "search"
          )
            return;
          self.showWt = true;
          self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
          self.$emit("change", "wt", "edit");
          if (self.mode == "search") self.setFilter(self.getFilter());
        }
      }
    );
    //Al seleccionar la divisa
    self.onChange(["divisa_id"], function (source) {
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.divisa_id) return;
      self.$set(
        self.formData,
        "cambio",
        floatOrZero(self.formData.divisa_id.ratio).toFixed(3)
      );
      self.$emit("change", "cambio", "auto");
    });
    //Al seleccionar el metal
    self.onChange(["metal_id"], function (source) {
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.metal_id) return;
      self.$set(
        self.formData,
        "prmetalp",
        floatOrZero(self.formData.metal_id.cotizacion).toFixed(3)
      );
      self.$set(
        self.formData,
        "factor",
        floatOrZero(self.formData.metal_id.factor).toFixed(3)
      );
      self.$emit("change", "prmetalp", "auto");
    });
    //Al cambiar el tipo de metal
    self.$refs.tipo_metal.field.$on("change", function () {
      if (self.formData.tipo_metal == "no") {
        self.$set(self.formData, "metal_id", null);
        self.$set(self.formData, "factor", null);
        self.$set(self.formData, "prmetalp", null);
        self.$set(self.formData, "merma", null);
        self.$emit("change", "prmetalp", "auto");
        self.$emit("change", "merma", "auto");
      }
    });
    // Al seleccionar un documento
    self.$refs.documentos_pendientes.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        h.cachedItems = h.cachedItems || {};
        h.cachedItems.divisa_id = h.cachedItems.divisa_id || {};
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          if (prop == "sel") {
            window.$.each(h.getSourceData(), function (i, r) {
              if (parseInt(r.sel)) {
                self.formData.ivap = r.ivap;
                self.formData.rep = r.rep;
                self.formData.importacion = r.importacion;
                self.formData.divisa_id = h.cachedItems.divisa_id[r.divisa_id];
                self.formData.cambio = r.cambio;
                self.$set(self.formData, "tipo_metal", r.tipo_metal);
                self.formData.metal_id = r.metal_id;
                self.formData.prmetalp = r.prmetalp;
                self.formData.factor = r.factor;
                self.formData.merma = r.merma;
                self.formData.contraste = r.contraste;
                self.formData.ocostes = r.ocostes;
                self.formData.mermaporlinea = r.mermaporlinea;
                //del pedido: dto_especial_porcentaje, financiacion_porcentaje, tipo_impuesto
                //del proveedor: formapago_id
                self.formData.dto_especial_porcentaje =
                  r.dto_especial_porcentaje;
                self.formData.financiacion_porcentaje =
                  r.financiacion_porcentaje;
                self.formData.tipo_impuesto = r.tipo_impuesto;
                self.$forceUpdate();
                return false;
              }
            });
          }
        });
      }
    );
    // Al seleccionar una línea de documento
    self.$refs.lineas_pendientes.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          if (prop == "sel") {
            self.actualizarTotales();
          }
        });
      }
    );

    self.$refs.lineas_pendientes.field.hotInstance.addHook(
      "beforeChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          if (prop == "sel") {
            changes.push([
              row,
              "unidades",
              null,
              newVal ? h.getSourceData()[row].unidades_pendientes : 0,
            ]);
            changes.push([
              row,
              "peso",
              null,
              newVal ? h.getSourceData()[row].peso_pendiente : 0,
            ]);
          }
          if (prop == "unidades") {
            let unidades = parseFloat(newVal || 0);
            let unidades_pendientes = parseFloat(
              h.getSourceData()[row].unidades_pendientes || 0
            );
            if (unidades > unidades_pendientes) {
              changes[index][3] = unidades_pendientes;
            } else if (unidades < 0) {
              changes[index][3] = unidades_pendientes;
            }
            if (unidades) {
              changes.push([row, "sel", null, 1]);
            } else {
              changes.push([row, "sel", null, 0]);
            }
            changes.push([
              row,
              "peso",
              null,
              (h.getSourceData()[row].peso / parseFloat(oldVal || 1)) *
                unidades,
            ]);
          }
          if (prop == "peso") {
            let peso = parseFloat(newVal || 0);
            let peso_pendiente = parseFloat(
              h.getSourceData()[row].peso_pendiente || 0
            );
            if (peso > peso_pendiente) {
              changes[index][3] = peso_pendiente;
            } else if (peso < 0) {
              changes[index][3] = peso_pendiente;
            }
            if (peso) {
              changes.push([row, "sel", null, 1]);
            } else {
              changes.push([row, "sel", null, 0]);
            }
          }
        });
      }
    );
  },
};
</script>
<style>
tr.wtdoc > td {
  background: #72dad7 !important;
  color: white !important;
}
</style>